export const AdminNdSiteAdminAssetsActionTypes = {
    GET_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN: '@@/layout/GET_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN',
    GET_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING:
        '@@/layout/GET_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING',
    GET_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS:
        '@@/layout/GET_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS',
    GET_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR: '@@/layout/GET_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR',

    GET_SOFTWARE_LIST: '@@/layout/GET_SOFTWARE_LIST',
    GET_SOFTWARE_LIST_LOADING:
        '@@/layout/GET_SOFTWARE_LIST_LOADING',
    GET_SOFTWARE_LIST_SUCCESS:
        '@@/layout/GET_SOFTWARE_LIST_SUCCESS',
    GET_SOFTWARE_LIST_ERROR: '@@/layout/GET_SOFTWARE_LIST_ERROR',

    ADD_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN: '@@/layout/ADD_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN',
    ADD_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING:
        '@@/layout/ADD_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING',
    ADD_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS:
        '@@/layout/ADD_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS',
    ADD_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR: '@@/layout/ADD_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR',
    ADD_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET: '@@/layout/ADD_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET',

    EDIT_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN: '@@/layout/EDIT_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN',
    EDIT_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING:
        '@@/layout/EDIT_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING',
    EDIT_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS:
        '@@/layout/EDIT_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS',
    EDIT_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR:
        '@@/layout/EDIT_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR',
    EDIT_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET:
        '@@/layout/EDIT_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET',

    STATUS_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN: '@@/layout/STATUS_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN',
    STATUS_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING:
        '@@/layout/STATUS_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING',
    STATUS_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS:
        '@@/layout/STATUS_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS',
    STATUS_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR:
        '@@/layout/STATUS_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR',
    STATUS_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET:
        '@@/layout/STATUS_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET',

    CSV_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN: '@@/layout/CSV_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN',
    CSV_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING:
        '@@/layout/CSV_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_LOADING',
    CSV_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS:
        '@@/layout/CSV_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS',
    CSV_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR: '@@/layout/CSV_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_ERROR',
    CSV_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET: '@@/layout/CSV_ASSETS_BY_COMPANY_ID_ADMIN_ND_SITE_ADMIN_RESET',

    DELETE_ASSETS: '@@/layout/DELETE_ASSETS',
    DELETE_ASSETS_LOADING:
        '@@/layout/DELETE_ASSETS_LOADING',
    DELETE_ASSETS_SUCCESS:
        '@@/layout/DELETE_ASSETS_SUCCESS',
    DELETE_ASSETS_ERROR: '@@/layout/DELETE_ASSETS_ERROR',
    DELETE_ASSETS_RESET: '@@/layout/DELETE_ASSETS_RESET',


    LATEST_VERSION: '@@/layout/LATEST_VERSION',
    LATEST_VERSION_LOADING: '@@/layout/LATEST_VERSION_LOADING',
    LATEST_VERSION_SUCCESS: '@@/layout/LATEST_VERSION_SUCCESS',
    LATEST_VERSION_ERROR: '@@/layout/LATEST_VERSION_ERROR',
    LATEST_VERSION_RESET: '@@/layout/LATEST_VERSION_RESET',

    STATE_EMPTY_INTIAL_STATE: '@@/layout/STATE_EMPTY_INTIAL_STATE',

};
