import { APICore } from '../../../helpers/api/apiCore';
import * as URL from '../../../constants/endPoint';

const api = new APICore();

function getPlansEndPoint(params: any): any {
    const { email } = params?.data;
    return api.get(`${URL.GET_PLANS}/${email}`);
}

export { getPlansEndPoint };
