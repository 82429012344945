import React, { useState, useRef, useEffect } from 'react';
import ScreenshotButton from '../screenshot/ScreenShot';
import CroppedScreenShot from '../screenshot/CroppedScreenShot';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import SupportModal from '../../pages/adminNdSideAdmin/help&support/model/SupportModal';

const ActionButton = () => {
    const [isCardOpen, setIsCardOpen] = useState(false);
    const cardRef = useRef(null);
    const [show, setShow] = useState(false);

    const openModal = () => {
        console.log('Opening modal');
        setShow(true);
    };

    const handleClose = () => {
        console.log('Closing modal');
        setShow(false);
    };

    const toggleCard = () => {
        setIsCardOpen(!isCardOpen);
    };

    const handleClickOutside = (event) => {
        if (cardRef.current && !cardRef.current.contains(event.target)) {
            setIsCardOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: '9999' }}>
            <button
                style={{
                    backgroundColor: '#007bff',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '50%',
                    width: '50px',
                    height: '50px',
                    fontSize: '24px',
                    cursor: 'pointer',
                    outline: 'none',
                    textAlign: 'center',
                    position: 'relative',
                }}
                onClick={toggleCard}>
                <i className="mdi mdi-robot-vacuum fs-2"></i>
            </button>
            {isCardOpen && (
                <div
                    ref={cardRef}
                    style={{
                        position: 'absolute',
                        top: '-135px',
                        right: '0',
                        width: '50px',
                        borderRadius: '8px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        zIndex: '9999',
                        padding: '10px',
                    }}
                    className="text-center bg-secondary">
                    <OverlayTrigger placement="left" overlay={<Tooltip id="overlay-example">Help & Support</Tooltip>}>
                        <i className="mdi mdi-help-circle-outline fs-3 text-light" onClick={openModal}></i>
                    </OverlayTrigger>
                    <CroppedScreenShot color={'text-light'} height={'fs-3'} />
                    <ScreenshotButton color={'text-light'} height={'fs-3'} />
                </div>
            )}
            <SupportModal show={show} hide={handleClose} />
        </div>
    );
};

export default ActionButton;
