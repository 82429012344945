import { BrowserRouter } from 'react-router-dom';
import { AllRoutes } from './index';
import ToastHandle from '../helpers/ToastMessage';
import { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { getUserFromSession } from '../helpers/api/apiCore';
import ActionButton from '../helpers/ActionButton/ActionButton';
import { useSelector } from 'react-redux';


const socket = io.connect("http://103.185.212.115:8861")
const Routes = () => {
    const store = useSelector((state) => state);
    const userInfo = getUserFromSession();
    const USERID = userInfo?.id
    const Loggedin = store?.Auth?.userLoggedIn;
    const LoggedOut = store?.Auth?.userLogout;
    const [notification, setNotification] = useState()
    const [userID, setUserID] = useState()
    // console.log({ userID, USERID, notification })
    socket.on('notifyUser', (data) => {
        setNotification(data?.notifications)
        setUserID(data?.userId)
    })
    useEffect(() => {
        if (userID && userID === USERID) {
            ToastHandle(notification, 'success');
        }
    }, [notification, userID, USERID]);
    return (
        <BrowserRouter>
            <AllRoutes />
            {Loggedin !== false && !LoggedOut ? <ActionButton /> : <></>}
        </BrowserRouter>
    );
};

export default Routes;
