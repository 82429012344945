import { AdminNdSiteAdminPlanActionTypes } from './constants';

const GET_PLANS_ADMIN_INITIAL_STATE = {
    getPlans: [],
    loading: false,
};


const getPlansReducer = (state = GET_PLANS_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminPlanActionTypes.GET_PLANS_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                getPlans: state.getPlans,
                loading: true,
            };
        case AdminNdSiteAdminPlanActionTypes.GET_PLANS_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                getPlans: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminPlanActionTypes.GET_PLANS_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                getPlans: 'Email Does Not Exist',
                loading: false,
            };
        case AdminNdSiteAdminPlanActionTypes.STATE_EMPTY_INTIAL_STATE:
            return GET_PLANS_ADMIN_INITIAL_STATE

        default:
            return state;
    }
};


export { getPlansReducer };
