import { all, fork, put, takeEvery, call, takeLatest } from 'redux-saga/effects';
import { AdminNdSiteAdminSupportActionTypes } from './constants';
import {
    getSupportEndPoint,
    createSupportEndpoint,
    getSupportByIdEndPoint,
    updateSupportEndpoint
} from './api';

function* getSupport(data) {
    try {
        yield put({
            type: AdminNdSiteAdminSupportActionTypes.GET_SUPPORT_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(getSupportEndPoint, data);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminSupportActionTypes.GET_SUPPORT_ADMIN_ND_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: AdminNdSiteAdminSupportActionTypes.GET_SUPPORT_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminSupportActionTypes.GET_SUPPORT_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
    }
}
function* getSupportById(data) {
    try {
        yield put({
            type: AdminNdSiteAdminSupportActionTypes.GET_SUPPORT_BY_ID_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(getSupportByIdEndPoint, data);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminSupportActionTypes.GET_SUPPORT_BY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: AdminNdSiteAdminSupportActionTypes.GET_SUPPORT_BY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminSupportActionTypes.GET_SUPPORT_BY_ID_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
    }
}

function* createSupport(data) {
    try {
        yield put({
            type: AdminNdSiteAdminSupportActionTypes.POST_SUPPORT_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        })
        const response = yield call(createSupportEndpoint, data);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminSupportActionTypes.POST_SUPPORT_ADMIN_ND_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            })
        } else {
            yield put({
                type: AdminNdSiteAdminSupportActionTypes.POST_SUPPORT_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            })
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminSupportActionTypes.POST_SUPPORT_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        })
    }
}
function* updateSupport(data) {
    try {
        yield put({
            type: AdminNdSiteAdminSupportActionTypes.PUT_SUPPORT_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        })
        const response = yield call(updateSupportEndpoint, data);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminSupportActionTypes.PUT_SUPPORT_ADMIN_ND_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            })
            yield put({
                type: AdminNdSiteAdminSupportActionTypes.PUT_SUPPORT_ADMIN_ND_SITE_ADMIN_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: AdminNdSiteAdminSupportActionTypes.PUT_SUPPORT_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            })
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminSupportActionTypes.PUT_SUPPORT_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        })
    }
}


function* callStateEmpty() {
    put({
        type: AdminNdSiteAdminSupportActionTypes.STATE_EMPTY_INTIAL_STATE,
        payload: {},
    });

}

export function* getSupportAction(): any {
    yield takeEvery(AdminNdSiteAdminSupportActionTypes.GET_SUPPORT_ADMIN_ND_SITE_ADMIN, getSupport);
    yield takeEvery(AdminNdSiteAdminSupportActionTypes.GET_SUPPORT_BY_ID_ADMIN_ND_SITE_ADMIN, getSupportById);
    yield takeEvery(AdminNdSiteAdminSupportActionTypes.POST_SUPPORT_ADMIN_ND_SITE_ADMIN, createSupport);
    yield takeLatest(AdminNdSiteAdminSupportActionTypes.PUT_SUPPORT_ADMIN_ND_SITE_ADMIN, updateSupport);
}

export function* stateEmptyAction(): any {
    yield takeEvery(
        AdminNdSiteAdminSupportActionTypes.STATE_EMPTY_INTIAL_STATE,
        callStateEmpty
    );
}


function* getSupportSaga(): any {
    yield all([
        fork(getSupportAction),
        fork(stateEmptyAction)

    ]);
}

export default getSupportSaga;
