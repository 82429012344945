export const AdminNdSiteAdminSupportActionTypes = {
    GET_SUPPORT_ADMIN_ND_SITE_ADMIN: '@@/layout/GET_SUPPORT_ADMIN_ND_SITE_ADMIN',
    GET_SUPPORT_ADMIN_ND_SITE_ADMIN_LOADING: '@@/layout/GET_SUPPORT_ADMIN_ND_SITE_ADMIN_LOADING',
    GET_SUPPORT_ADMIN_ND_SITE_ADMIN_SUCCESS: '@@/layout/GET_SUPPORT_ADMIN_ND_SITE_ADMIN_SUCCESS',
    GET_SUPPORT_ADMIN_ND_SITE_ADMIN_ERROR: '@@/layout/GET_SUPPORT_ADMIN_ND_SITE_ADMIN_ERROR',

    GET_SUPPORT_BY_ID_ADMIN_ND_SITE_ADMIN: '@@/layout/GET_SUPPORT_BY_ID_ADMIN_ND_SITE_ADMIN',
    GET_SUPPORT_BY_ID_ADMIN_ND_SITE_ADMIN_LOADING: '@@/layout/GET_SUPPORT_BY_ID_ADMIN_ND_SITE_ADMIN_LOADING',
    GET_SUPPORT_BY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS: '@@/layout/GET_SUPPORT_BY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS',
    GET_SUPPORT_BY_ID_ADMIN_ND_SITE_ADMIN_ERROR: '@@/layout/GET_SUPPORT_BY_ID_ADMIN_ND_SITE_ADMIN_ERROR',

    POST_SUPPORT_ADMIN_ND_SITE_ADMIN: '@@/layout/POST_SUPPORT_ADMIN_ND_SITE_ADMIN',
    POST_SUPPORT_ADMIN_ND_SITE_ADMIN_LOADING: '@@/layout/POST_SUPPORT_ADMIN_ND_SITE_ADMIN_LOADING',
    POST_SUPPORT_ADMIN_ND_SITE_ADMIN_SUCCESS: '@@/layout/POST_SUPPORT_ADMIN_ND_SITE_ADMIN_SUCCESS',
    POST_SUPPORT_ADMIN_ND_SITE_ADMIN_ERROR: '@@/layout/POST_SUPPORT_ADMIN_ND_SITE_ADMIN_ERROR',

    PUT_SUPPORT_ADMIN_ND_SITE_ADMIN: '@@/layout/PUT_SUPPORT_ADMIN_ND_SITE_ADMIN',
    PUT_SUPPORT_ADMIN_ND_SITE_ADMIN_LOADING: '@@/layout/PUT_SUPPORT_ADMIN_ND_SITE_ADMIN_LOADING',
    PUT_SUPPORT_ADMIN_ND_SITE_ADMIN_SUCCESS: '@@/layout/PUT_SUPPORT_ADMIN_ND_SITE_ADMIN_SUCCESS',
    PUT_SUPPORT_ADMIN_ND_SITE_ADMIN_RESET: '@@/layout/PUT_SUPPORT_ADMIN_ND_SITE_ADMIN_RESET',
    PUT_SUPPORT_ADMIN_ND_SITE_ADMIN_ERROR: '@@/layout/PUT_SUPPORT_ADMIN_ND_SITE_ADMIN_ERROR',

    STATE_EMPTY_INTIAL_STATE: '@@/layout/STATE_EMPTY_INTIAL_STATE',
};
