import React, { useState, useRef, useEffect, useCallback } from 'react';
import { ScreenCapture } from 'react-screen-capture';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import SupportModal from '../../pages/adminNdSideAdmin/help&support/model/SupportModal';

const CroppedScreenShot = ({ color, height }) => {
    const store = useSelector((state) => state);

    const [capturing, setCapturing] = useState(false);
    const [capturedImage, setCapturedImage] = useState(null);
    const [showCard, setShowCard] = useState(false);
    const cardRef = useRef(null);
    const userRole = store?.Auth?.user?.role;
    const createSupportStatus = store?.createSupportReducer?.createSupport?.status;

    const handleCapture = useCallback((uri) => {
        setCapturedImage(uri);
        setCapturing(true);
        setShowCard(true);
    }, []);

    // const handleClickOutside = useCallback((event) => {
    //     if (cardRef.current && !cardRef.current.contains(event.target)) {
    //         setShowCard(false);
    //     }
    // }, []);

    // useEffect(() => {
    //     document.addEventListener('mousedown', handleClickOutside);
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, [handleClickOutside]);

    const currentPageURL = window.location.href;
    const currentPageName = currentPageURL.substring(currentPageURL.lastIndexOf('/') + 1).replace(/\b\w/g, (c) => c.toUpperCase());

    const handleDownload = () => {
        if (capturedImage) {
            let link = document.createElement('a');
            link.href = capturedImage;
            link.download = `${currentPageName}_screenshot.png`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const handleCancel = () => {
        setShowCard(false);
        setCapturedImage(null);
    };

    const [show, setShow] = useState(false);

    const handleCloseHelpModal = () => {
        setShow(false)
    };

    const openHelpAndSupportModal = () => {
        setShow(true);
        setShowCard(false);
    };

    useEffect(() => {
        if (createSupportStatus === 200) {
            setCapturedImage(null);
        }
    }, [createSupportStatus]);

    return (
        <div style={{ position: 'relative' }}>
            <style>{`.crosshairs {
                position: fixed;
                width:auto;
                z-index: 2147483645;
            }`}</style>
            <ScreenCapture onEndCapture={handleCapture}>
                {({ onStartCapture }) => (
                    <OverlayTrigger placement="left" overlay={<Tooltip id="overlay-example">Select Area</Tooltip>}>
                        <i
                            className={`mdi mdi-select-drag ${color} ${height}`}
                            onClick={onStartCapture}
                            disabled={capturing}
                        />
                    </OverlayTrigger>
                )}
            </ScreenCapture>
            {showCard && (
                <div
                    ref={cardRef}
                    style={{
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-20%, -60%)',
                        border: '1px solid #ccc',
                        padding: '10px',
                        width: '400px',
                        backgroundColor: '#f8f9fa',
                    }}
                    className="bg-dark"
                >
                    {capturedImage && (
                        <img
                            src={capturedImage}
                            alt="Captured Screenshot"
                            style={{ maxWidth: '100%', height: 'auto' }}
                        />
                    )}
                    <div className="d-flex justify-content-between py-0 my-0">
                        <code className='mt-2 text-nowrap'>{currentPageName} </code>
                        <div className="d-flex justify-content-end">
                            <OverlayTrigger placement="left" overlay={<Tooltip id="overlay-example">Add</Tooltip>}>
                                <i
                                    className="mdi mdi-share fs-3 text-primary pe-2 pb-0 my-0"
                                    title="Share"
                                    onClick={openHelpAndSupportModal}
                                />
                            </OverlayTrigger>
                            <OverlayTrigger placement="left" overlay={<Tooltip id="overlay-example">Save</Tooltip>}>
                                <i
                                    className="mdi mdi-tray-arrow-down fs-3 text-success pe-2 pb-0 my-0"
                                    title="Save"
                                    onClick={handleDownload}
                                />
                            </OverlayTrigger>
                            <OverlayTrigger placement="left" overlay={<Tooltip id="overlay-example">Cancel</Tooltip>}>
                                <i
                                    className="mdi mdi-close-circle-outline fs-3 text-danger pb-0 my-0"
                                    title="Cancel"
                                    onClick={handleCancel}
                                />
                            </OverlayTrigger>
                        </div>
                    </div>
                </div>
            )}
            <SupportModal show={show} hide={handleCloseHelpModal} file={capturedImage} />
        </div>
    );
};

export default CroppedScreenShot;
