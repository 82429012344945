import { APICore } from "../../../helpers/api/apiCore";
import * as URL from '../../../constants/endPoint';

const api = new APICore();

function postReport(params: any): any {
    const { data } = params;
    console.log({ data })
    return api.create(URL.POST_REPORT, data)
}

export {
    postReport,
}