import { AdminNdSiteAdminSupportActionTypes } from './constants';

const SUPPORT_ADMIN_INITIAL_STATE = {
    support: [],
    loading: false,
};


const getSupportReducer = (state = SUPPORT_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminSupportActionTypes.GET_SUPPORT_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                support: state.support,
                loading: true,
            };
        case AdminNdSiteAdminSupportActionTypes.GET_SUPPORT_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                support: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminSupportActionTypes.GET_SUPPORT_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                support: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminSupportActionTypes.STATE_EMPTY_INTIAL_STATE:
            return SUPPORT_ADMIN_INITIAL_STATE

        default:
            return state;
    }
};
const getSupportByIdReducer = (state = SUPPORT_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminSupportActionTypes.GET_SUPPORT_BY_ID_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                support: state.support,
                loading: true,
            };
        case AdminNdSiteAdminSupportActionTypes.GET_SUPPORT_BY_ID_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                support: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminSupportActionTypes.GET_SUPPORT_BY_ID_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                support: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminSupportActionTypes.STATE_EMPTY_INTIAL_STATE:
            return SUPPORT_ADMIN_INITIAL_STATE

        default:
            return state;
    }
};

const createSupportReducer = (state = SUPPORT_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminSupportActionTypes.POST_SUPPORT_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                support: state.support,
                loading: true,
            };
        case AdminNdSiteAdminSupportActionTypes.POST_SUPPORT_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                support: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminSupportActionTypes.STATE_EMPTY_INTIAL_STATE:
            return SUPPORT_ADMIN_INITIAL_STATE
        case AdminNdSiteAdminSupportActionTypes.POST_SUPPORT_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                support: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminSupportActionTypes.STATE_EMPTY_INTIAL_STATE:
            return SUPPORT_ADMIN_INITIAL_STATE

        default:
            return state;
    }
};

const updateSupportReducer = (state = SUPPORT_ADMIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminNdSiteAdminSupportActionTypes.PUT_SUPPORT_ADMIN_ND_SITE_ADMIN_LOADING:
            return {
                support: state.support,
                loading: true,
            };
        case AdminNdSiteAdminSupportActionTypes.PUT_SUPPORT_ADMIN_ND_SITE_ADMIN_SUCCESS:
            return {
                support: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminSupportActionTypes.PUT_SUPPORT_ADMIN_ND_SITE_ADMIN_RESET:
            return {
                support: {},
                loading: false,
            };
        case AdminNdSiteAdminSupportActionTypes.PUT_SUPPORT_ADMIN_ND_SITE_ADMIN_ERROR:
            return {
                support: action.payload,
                loading: false,
            };
        case AdminNdSiteAdminSupportActionTypes.STATE_EMPTY_INTIAL_STATE:
            return SUPPORT_ADMIN_INITIAL_STATE;

        default:
            return state;
    }
};


export { getSupportReducer, createSupportReducer, getSupportByIdReducer, updateSupportReducer };
