import { APICore } from '../../../helpers/api/apiCore';
import * as URL from "../../../constants/endPoint"

const api = new APICore();

function getTaskListEndPoint(params: any): any {
    const { search, limit, page, company_id } = params.data
    return api.get(`${URL.GET_TASK_LIST}search=${search}&limit=${limit}&page=${page}&company_id=${company_id} `);
}


function getTaskStatusEndPoint(params: any): any {
    return api.get(URL.GET_TASK_STATUS);
}

function addStartTaskEndPoint(params: any): any {
    const { data } = params
    return api.update(URL.ADD_START_TASK, data);
}
function addCommentEndPoint(params: any): any {
    const { data } = params
    return api.create(URL.POST_COMMENTS, data);
}

export {
    getTaskListEndPoint,
    getTaskStatusEndPoint,
    addStartTaskEndPoint,
    addCommentEndPoint
}