import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { ProfileUpdateActionTypes } from './constants';
import { porfileUpdateEndPoint, forgetPasswordEndPoint } from './api';


// start cash day Report

function* callUpdateProfile(data) {
    try {
        localStorage.removeItem("imagedefault");
        yield put({
            type: ProfileUpdateActionTypes.PROFILE_UPDATE_LOADING,
            payload: {},
        });
        const response = yield call(porfileUpdateEndPoint, data);
        const role = response?.data?.response?.role
        const defaultImage = response?.data?.response?.plans?.[0]?.picture || response?.data?.response?.plans?.[1]?.picture
        console.log({ defaultImage })
        // localStorage.setItem("imagedefault", defaultImage);
        if (role === 'super-admin') {
            localStorage.setItem("super_admin_image", defaultImage);
        } else if (role === 'admin') {
            localStorage.setItem("admin_image", defaultImage);

        } else if (role === 'site-admin') {
            localStorage.setItem("site_admin_image", defaultImage);

        } else if (role === 'user') {
            localStorage.setItem("user_image", defaultImage);

        }
        // if (response.data.status === true) {
        //     localStorage.setItem("imagedefault", defaultImage);
        // } else {
        //     localStorage.removeItem('imagedefault')

        // }
        if (response.data.status) {
            yield put({
                type: ProfileUpdateActionTypes.PROFILE_UPDATE_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: ProfileUpdateActionTypes.PROFILE_UPDATE_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: ProfileUpdateActionTypes.PROFILE_UPDATE_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: ProfileUpdateActionTypes.PROFILE_UPDATE_ERROR,
            payload: error,
        });
    }
}

function* callForgetPassword(data) {
    try {
        yield put({
            type: ProfileUpdateActionTypes.FORGET_PASSWORD_UPDATE_LOADING,
            payload: {},
        });
        const response = yield call(forgetPasswordEndPoint, data);

        if (response.data.status) {
            yield put({
                type: ProfileUpdateActionTypes.FORGET_PASSWORD_UPDATE_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: ProfileUpdateActionTypes.FORGET_PASSWORD_UPDATE_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: ProfileUpdateActionTypes.FORGET_PASSWORD_UPDATE_ERROR,
                payload: { ...response.data },
            });
            yield put({
                type: ProfileUpdateActionTypes.FORGET_PASSWORD_UPDATE_RESET,
                payload: {},
            });
        }
    } catch (error) {
        const obj = {
            status: false,
            error: error
        }
        yield put({
            type: ProfileUpdateActionTypes.FORGET_PASSWORD_UPDATE_ERROR,
            payload: obj,
        });
        yield put({
            type: ProfileUpdateActionTypes.FORGET_PASSWORD_UPDATE_RESET,
            payload: {},
        });
    }
}
// end cash day Report List


export function* updateProfileAction(): any {
    yield takeEvery(ProfileUpdateActionTypes.PROFILE_UPDATE_FIRST, callUpdateProfile);
}

export function* forgetPasswordAction(): any {
    yield takeEvery(ProfileUpdateActionTypes.FORGET_PASSWORD_UPDATE_FIRST, callForgetPassword);
}


function* profileUpdateSaga(): any {
    yield all([
        fork(updateProfileAction),
        fork(forgetPasswordAction)
    ]);
}

export default profileUpdateSaga;
