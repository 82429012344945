import { AdminNdSiteAdminPlanActionTypes } from "./constants";

export const getPlan = (data): LayoutAction => ({
    type: AdminNdSiteAdminPlanActionTypes.GET_PLANS_ADMIN_ND_SITE_ADMIN,
    data

});

export const stateEmptydAction = (): LayoutAction => ({
    type: AdminNdSiteAdminPlanActionTypes.STATE_EMPTY_INTIAL_STATE,

});


