import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { AdminNdSiteAdminPlanActionTypes } from './constants';
import {
    getPlansEndPoint,
} from './api';

function* gatPlansWhileLogin(data) {
    try {
        yield put({
            type: AdminNdSiteAdminPlanActionTypes.GET_PLANS_ADMIN_ND_SITE_ADMIN_LOADING,
            payload: {},
        });
        const response = yield call(getPlansEndPoint, data);
        if (response.data.status) {
            yield put({
                type: AdminNdSiteAdminPlanActionTypes.GET_PLANS_ADMIN_ND_SITE_ADMIN_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: AdminNdSiteAdminPlanActionTypes.GET_PLANS_ADMIN_ND_SITE_ADMIN_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: AdminNdSiteAdminPlanActionTypes.GET_PLANS_ADMIN_ND_SITE_ADMIN_ERROR,
            payload: error,
        });
    }
}


function* callStateEmpty() {
    put({
        type: AdminNdSiteAdminPlanActionTypes.STATE_EMPTY_INTIAL_STATE,
        payload: {},
    });

}

export function* getPlansAction(): any {
    yield takeEvery(AdminNdSiteAdminPlanActionTypes.GET_PLANS_ADMIN_ND_SITE_ADMIN, gatPlansWhileLogin);

}

export function* stateEmptyAction(): any {
    yield takeEvery(
        AdminNdSiteAdminPlanActionTypes.STATE_EMPTY_INTIAL_STATE,
        callStateEmpty
    );
}


function* getPlansSaga(): any {
    yield all([
        fork(getPlansAction),
        fork(stateEmptyAction)

    ]);
}

export default getPlansSaga;
