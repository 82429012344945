import React, { useEffect, useState } from 'react';
import { createSupport, getSupport } from '../../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { getUserFromSession } from '../../../../helpers/api/apiCore';

const SupportModal = ({ show, hide, file }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [attachment, setAttachment] = useState(null);
    const [description, setDescription] = useState('');
    const [issueType, setIssueType] = useState('');
    const [priorityType, setPriorityType] = useState('medium');
    const store = useSelector((state) => state);
    const userId = store?.Auth?.user?.id;
    // const Role = store?.Auth?.user?.role;
    const CompanyId = store?.Auth?.user?.cmId;

    const getCurrentPageInfo = () => {
        const currentPageURL = location.pathname;
        const currentPageName = currentPageURL
            .substring(currentPageURL.lastIndexOf('/') + 1)
            .replace(/-/g, ' ') // Replace hyphens with spaces
            .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space between lowercase and uppercase letters
            .replace(/\b\w/g, (c) => c.toUpperCase());
        return { currentPageURL, currentPageName };
    };

    // Function to convert Data URL to Blob
    function dataURLtoBlob(dataURL) {
        if (!dataURL) {
            return null; // Return null if dataURL is not provided
        }
        // Split the dataURL into metadata and base64-encoded data
        const parts = dataURL.split(',');
        const contentType = parts[0].split(':')[1].split(';')[0];
        const byteString = atob(parts[1]);

        // Create an ArrayBuffer and a Uint8Array to store binary data
        const buffer = new ArrayBuffer(byteString.length);
        const uintArray = new Uint8Array(buffer);

        // Convert each character in the byteString to its charCode and store in the Uint8Array
        for (let i = 0; i < byteString.length; i++) {
            uintArray[i] = byteString.charCodeAt(i);
        }

        // Create a Blob from the ArrayBuffer and return it
        return new Blob([buffer], { type: contentType });
    }
    const user = getUserFromSession();
    const role = user?.role?.replace('-', '')

    useEffect(() => {
        console.log('Component mounted or updated');
        return () => console.log('Component will unmount');
    }, []);

    const onSubmitData = (e) => {
        e.preventDefault();
        const image = file ? dataURLtoBlob(file) : null;
        const formData = new FormData();
        formData.append('pageName', getCurrentPageInfo().currentPageName);
        formData.append('attachment', attachment || image);
        formData.append('issueType', issueType);
        formData.append('priority', priorityType);
        formData.append('discription', description);
        formData.append('user_id', userId);
        formData.append('company_id', CompanyId);

        // Dispatch action to create support
        dispatch(createSupport(formData));
        dispatch(getSupport({
            search: '', limit: 10, page: 1, CompanyId: (role === 'admin' || role === 'superadmin' ? '' : CompanyId)

        }))
        // Clear form fields and hide modal
        setAttachment(null);
        setDescription('');
        setIssueType('');
        setPriorityType('');
        hide();
    };
    const close = () => {
        setAttachment(null);
        setDescription('');
        setIssueType('');
        setPriorityType('');
        hide();
    }

    const handleContentClick = (e) => {
        e.stopPropagation();
    };

    return (
        <div>
            <Modal show={show} onHide={close} centered style={{ background: '#3b39399c' }}>
                <div className="modal-content" onMouseDown={handleContentClick}>
                    <Modal.Header className="bg-info text-white py-0">
                        <Modal.Title>Help & Support</Modal.Title>
                        <i className="mdi mdi-close fs-3" onClick={close}></i>
                    </Modal.Header>
                    <Modal.Body className="py-1">
                        <form onSubmit={onSubmitData}>
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-7">
                                            <div className="col-md-12 mt-2">
                                                <h4>
                                                    Name:{' '}
                                                    <span className="text-success fw-bold">
                                                        {getCurrentPageInfo().currentPageName}
                                                    </span>
                                                </h4>
                                            </div>
                                            <div className="col-md-12 mt-2">
                                                <div className="form-group d-flex">
                                                    <label className=" pe-2 fw-bold ">URL:</label>
                                                    <a href={location.pathname} title="view">
                                                        {location.pathname.slice(0, 42)}
                                                    </a>{' '}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 mt-2">
                                    {file ? (
                                        <div>
                                            <label htmlFor="image">Attachment</label>
                                            <img src={file} alt="" className="w-50 h-50 border ms-2 p-1 bg-secondary" />
                                        </div>
                                    ) : (
                                        <div className="form-group">
                                            <label htmlFor="image">Attachment</label>
                                            <input
                                                type="file"
                                                id="image"
                                                className="form-control"
                                                accept="image/*"
                                                required
                                                onChange={(e) => setAttachment(e.target.files[0])}
                                            />
                                        </div>

                                    )}
                                </div>
                                <div className="col-md-12 mt-2">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label htmlFor="issueType">Issue Type</label>
                                                <select
                                                    id="issueType"
                                                    className="form-control"
                                                    value={issueType}
                                                    required
                                                    onChange={(e) => setIssueType(e.target.value)}>
                                                    <option value="" disabled>
                                                        Select Issue Type
                                                    </option>
                                                    <option value="bug">Bug</option>
                                                    <option value="recommended">Recommendation</option>
                                                    <option value="enhancement">Enhancement</option>
                                                    <option value="other">Other</option>
                                                </select>
                                                {issueType === 'other' ? (
                                                    <span className='text-danger small'>Describe Below <i className='mdi mdi-arrow-down-bold'></i></span>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label htmlFor="priorityType">Priority</label>
                                                <select
                                                    id="priorityType"
                                                    className="form-control"
                                                    value={priorityType}
                                                    required
                                                    onChange={(e) => setPriorityType(e.target.value)}>
                                                    <option value="" hidden>
                                                        Select Priority Type
                                                    </option>
                                                    <option value="critical">Critical</option>
                                                    <option value="high">High</option>
                                                    <option value="medium">Medium</option>
                                                    <option value="low">Low</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 mt-2">
                                    <div className="form-group">
                                        <label htmlFor="description">Tell us more about {issueType ? issueType : <>Issue</>}</label>
                                        <textarea
                                            id="description"
                                            className="form-control"
                                            rows="3"
                                            required
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}></textarea>
                                    </div>
                                </div>
                                <div className="col-md-12 mt-2 d-flex justify-content-between align-items-center">
                                    <span style={{ fontSize: '11px' }}>
                                        {' '}
                                        © {new Date().getFullYear()} Almata Co., Ltd. All rights reserved.{' '}
                                    </span>
                                    <Button type="submit" className="btn btn-primary">
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                </div>
            </Modal>
        </div>
    );
};

export default SupportModal;
