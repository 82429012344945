import { AdminNdSiteAdminSupportActionTypes } from "./constants";

export const getSupport = (data): LayoutAction => ({
    type: AdminNdSiteAdminSupportActionTypes.GET_SUPPORT_ADMIN_ND_SITE_ADMIN,
    data
});

export const getSupportById = (data): LayoutAction => ({
    type: AdminNdSiteAdminSupportActionTypes.GET_SUPPORT_BY_ID_ADMIN_ND_SITE_ADMIN,
    data
});

export const createSupport = (data): LayoutAction => ({
    type: AdminNdSiteAdminSupportActionTypes.POST_SUPPORT_ADMIN_ND_SITE_ADMIN,
    data

});

export const updateSupport = (data): LayoutAction => ({
    type: AdminNdSiteAdminSupportActionTypes.PUT_SUPPORT_ADMIN_ND_SITE_ADMIN,
    data

});

export const stateEmptydAction = (): LayoutAction => ({
    type: AdminNdSiteAdminSupportActionTypes.STATE_EMPTY_INTIAL_STATE,

});


