import { reportActionTypes } from "./constants";
import { all, put, fork, takeEvery, takeLatest, call } from "redux-saga/effects";
import { postReport } from "./api";


function* postReportEndpoint(data) {
    try {
        yield put({
            type: reportActionTypes.POST_REPORT_LOADING,
            payload: {},
        })
        const response = yield call(postReport, data);
        if (response.data.status) {
            yield put({
                type: reportActionTypes.POST_REPORT_SUCCESS,
                payload: { ...response.data },
            })
        } else {
            yield put({
                type: reportActionTypes.POST_REPORT_ERROR,
                payload: { ...response.data },
            })
        }
    } catch (error) {
        yield put({
            type: reportActionTypes.POST_REPORT_ERROR,
            payload: error,
        })
    }
}

export function* watchPostReport(): any {
    yield takeEvery(reportActionTypes.POST_REPORT_FIRST, postReportEndpoint)
}


function* reportSaga(): any {
    yield all([
        fork(watchPostReport),
    ]);
}

export default reportSaga;