import { APICore, getUserFromSession } from '../../../helpers/api/apiCore';
import * as URL from '../../../constants/endPoint';

const api = new APICore();
const user = getUserFromSession();
// const role = user?.role

function getSupportEndPoint(params: any): any {
    const { search, limit, page, CompanyId } = params?.data;
    return api.get(`${URL.GET_SUPPORT}search=${search}&limit=${limit}&page=${page}&company_id=${CompanyId}`);
}
// role === 'admin' && role === 'super-admin' ? ('') : (CompanyId)

function getSupportByIdEndPoint(params: any): any {
    const { id } = params?.data;
    return api.get(`${URL.GET_SUPPORT_BY_ID}${id}`);
}

function createSupportEndpoint(params: any): any {
    const SupportData = params?.data;
    return api.create(URL.POST_SUPPORT, SupportData);
}
function updateSupportEndpoint(params: any): any {
    const SupportData = params?.data;
    return api.update(URL.PUT_SUPPORT, SupportData);
}


export { getSupportEndPoint, createSupportEndpoint, getSupportByIdEndPoint, updateSupportEndpoint };
