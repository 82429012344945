import { reportActionTypes } from "./constants";

const POST_REPORT_INITIAL_STATE = {
    getReport: [],
    loading: false
};


const postReportReducer = (state = POST_REPORT_INITIAL_STATE, action) => {
    switch (action.type) {
        case reportActionTypes.POST_REPORT_LOADING:
            return {
                getReport: state.getReport,
                loading: true,
            }
        case reportActionTypes.POST_REPORT_SUCCESS:
            return {
                getReport: action.payload,
                loading: false,
            }
        case reportActionTypes.POST_REPORT_ERROR:
            return {
                getReport: action.payload,
                loading: false,
            }
        default:
            return state;
    }

};

export {
    postReportReducer,
}
