import { Navigate } from 'react-router-dom';
import { getUserFromSession } from '../helpers/api/apiCore';
// import { getMenuByRole } from '../constants/menu';

const RootFind = () => {
    const user = getUserFromSession();
    const forcePasswordCheck = user?.data?.data?.user?.forcePassword;

    if (forcePasswordCheck === 1) {
        return 'account/forgetpassword';
    } else {
        if (user?.role === 'super-admin') {
            return 'superadmin/dashboard/ciodashboard';
        } else if (user?.role === 'admin') {
            return 'admin/dashboard/instance';
        } else if (user?.role === 'site-admin') {
            // Check for the plan and return the corresponding dashboard
            if (user?.data?.data?.user?.planname === 'Eagle Eye') {
                return 'siteadmin/dashboard/riskdashboard';
            } else if (user?.data?.data?.user?.planname === 'Eagle Talon') {
                return 'siteadmin/dashboard/itdashboard';
            }
        } else if (user?.role === 'user') {
            if (user?.data?.data?.user?.planname === 'Eagle Eye') {
                return 'user/dashboard/riskdashboard';
            } else if (user?.data?.data?.user?.planname === 'Eagle Talon') {
                return 'user/dashboard/itdashboard';
            }
        }
        return 'account/login';
    }


};

const Root = () => {
    const getRootUrl = () => {
        let url = RootFind();
        return url;
    };

    const url = getRootUrl();

    return <Navigate to={`/${url}`} />;
};

export default Root;
