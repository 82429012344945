export const reportActionTypes = {

    POST_REPORT_FIRST: '@@/layout/POST_REPORT_ADMIN_ND_SITE_ADMIN',
    POST_REPORT_LOADING: '@@/layout/POST_REPORT_LOADING',
    POST_REPORT_SUCCESS: '@@/layout/POST_REPORT_SUCCESS',
    POST_REPORT_ERROR: '@@/layout/POST_REPORT_ERROR',




}